import "./Quincy.scss";
import { Col, Form, Input, Row } from "antd";
import { ReactComponent as SearchIcon } from "shared/assets/images/icon-search.svg";
import { ReactComponent as QuincyChat } from "shared/assets/images/icon-quincy-chat.svg";
import { ReactComponent as CalendarIcon } from "shared/assets/images/calendar-icon.svg";
import { ReactComponent as DiscIcon } from "shared/assets/images/disc-icon.svg";
import { ReactComponent as WalletIcon } from "shared/assets/images/wallet-icon.svg";
import { ReactComponent as HandMetalIcon } from "shared/assets/images/hand-metal-icon.svg";
import chatLogoIcon from "shared/assets/images/chat-logo-icon.png";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { UserTypeEnum } from "shared/enums/UserTypeEnum";
import { isMobile } from "react-device-detect";
import { chatStreaming } from "shared/service/StrmStreamingService";
import { TypingEffect } from "./TypingEffect";
import Markdown from "markdown-to-jsx";
import { useTranslation } from "react-i18next";

enum ChatPredefinedQuestionEnum {
  AboutMyCareerStage = 0,
  HowCanIGoToNextStage = 1,
  ExplainAboutAdvance = 2,
  Recommendations = 3,
}

const Quincy: React.FC = (_) => {
  const { t } = useTranslation();
  const lastMessageRef = useRef<null | HTMLDivElement>(null);
  const { loggedUser } = useSelector((state: any) => state.users);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [outputTextRealtime, setOutputTextRealtime] = useState<string | null>(
    null
  );
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState<
    {
      role: string;
      content: string;
      hide: boolean;
    }[]
  >([]);
  const isAdmin = loggedUser.typeAccess === UserTypeEnum.AdminStrm;
  const shouldShowSugestion = messages.length === 0 && !isMobile;
  const receivingAnswear = outputTextRealtime || sendDisabled;

  if (!isAdmin) return <p>Não admin</p>;

  const prepareForNewQuestion = (question: string) => {
    setSendDisabled(true);
    setInputText("");
    setOutputTextRealtime(null);
    const lastUserMessage = {
      role: "user",
      content: question,
      hide: false,
    };

    return lastUserMessage;
  };

  const handleStreamingResponse = async (response: Response | null) => {
    let outputText = "";
    const reader = response?.body?.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { value, done } = await reader?.read()!;
      if (done) {
        setOutputTextRealtime(outputText);
        lastMessageRef?.current?.scrollIntoView();
        break;
      }

      outputText += decoder.decode(value, { stream: true });
    }
  };

  const submitQuestion = async (
    question: string,
    predefinedQuestion?: ChatPredefinedQuestionEnum
  ) => {
    if (sendDisabled || question === "") return false;

    let newMessages = messages ?? [];

    try {
      if (outputTextRealtime) {
        const lastSystemMessage = {
          role: "system",
          content: outputTextRealtime!,
          hide: false,
        };
        newMessages.push(lastSystemMessage);
      }

      const userMessage = prepareForNewQuestion(question);
      newMessages.push(userMessage);
      setMessages(newMessages);

      const request = {
        previousMessages: newMessages,
        predefinedQuestion: predefinedQuestion ?? null,
        prompt: question,
      };
      console.debug("allMessages", newMessages);
      console.debug("request", request);

      const response = await chatStreaming(request);

      await handleStreamingResponse(response);
    } catch (error) {
      console.error("Error during the request:", error);
    }
  };

  const isSystemMessage = (role: string) => role === "system";

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      submitQuestion(inputText);
    }
  };

  return (
    <div className={`page-quincy ${isMobile && "mobile"}`}>
      <Row>
        <Col className="header" xs={24}>
          <h1 className="title">{t("pages.Quincy.quincy")}</h1>
          <h3 className="subtitle">{t("pages.Quincy.title")}</h3>
        </Col>
      </Row>
      <Row justify="center" className="content" style={{ margin: "0 auto" }}>
        <div className={`chat-wrapper ${isMobile && "mobile"}`}>
          <Col className="chat-container">
            {messages
              .filter((x) => !x.hide)
              .map((ev, index) => (
                <div
                  className={`message ${
                    isSystemMessage(ev.role) ? "message-system" : "message-user"
                  }`}
                  key={index}
                >
                  {isSystemMessage(ev.role) && (
                    <div className="message-icon">
                      <QuincyChat />
                    </div>
                  )}

                  <div className="paragraph">
                    <Markdown>{ev.content}</Markdown>
                  </div>
                </div>
              ))}
            {receivingAnswear && (
              <div className={`message message-system`}>
                <div className="message-icon" ref={lastMessageRef}>
                  <QuincyChat />
                </div>
                <div className="paragraph">
                  {outputTextRealtime ? (
                    <TypingEffect
                      fullText={outputTextRealtime}
                      onComplete={() => {
                        setSendDisabled(false);
                      }}
                    />
                  ) : (
                    "..."
                  )}
                </div>
              </div>
            )}
          </Col>
          {shouldShowSugestion && (
            <div style={{ width: "100%" }}>
              <Row justify="center">
                <Col>
                  <img
                    src={chatLogoIcon}
                    alt="chatLogoIcon"
                    className="chat-logo-icon"
                  />
                </Col>
              </Row>
              <Row className="row-sugestion">
                <Col xs={6}>
                  <div
                    className="sugestion"
                    onClick={() => {
                      submitQuestion(
                        t("pages.Quincy.prefinedQuestionAboutMyCareerStage"),
                        ChatPredefinedQuestionEnum.AboutMyCareerStage
                      );
                    }}
                  >
                    <DiscIcon />
                    <p>
                      {t("pages.Quincy.prefinedQuestionAboutMyCareerStage")}
                    </p>
                  </div>
                </Col>
                <Col xs={6}>
                  <div
                    className="sugestion"
                    onClick={() =>
                      submitQuestion(
                        t("pages.Quincy.prefinedQuestionHowCanIGoToNextStage"),
                        ChatPredefinedQuestionEnum.HowCanIGoToNextStage
                      )
                    }
                  >
                    <HandMetalIcon />
                    <p>
                      {t("pages.Quincy.prefinedQuestionHowCanIGoToNextStage")}
                    </p>
                  </div>
                </Col>
                <Col xs={6}>
                  <div
                    className="sugestion"
                    onClick={() =>
                      submitQuestion(
                        t("pages.Quincy.prefinedQuestionExplainAboutAdvance"),
                        ChatPredefinedQuestionEnum.ExplainAboutAdvance
                      )
                    }
                  >
                    <WalletIcon />
                    <p>
                      {t("pages.Quincy.prefinedQuestionExplainAboutAdvance")}
                    </p>
                  </div>
                </Col>
                <Col xs={6}>
                  <div
                    className="sugestion"
                    onClick={() =>
                      submitQuestion(
                        t("pages.Quincy.prefinedQuestionRecommendations"),
                        ChatPredefinedQuestionEnum.Recommendations
                      )
                    }
                  >
                    <CalendarIcon />
                    <p>{t("pages.Quincy.prefinedQuestionRecommendations")}</p>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <div
            className={`row-search ${
              (messages.length || isMobile) && "exist-message"
            }`}
          >
            <Form.Item style={{ marginBottom: 0 }}>
              <Input
                placeholder={t("pages.Quincy.placeholder")}
                className="input-text"
                suffix={
                  <SearchIcon
                    onClick={() => submitQuestion(inputText)}
                    className={`${
                      (sendDisabled || inputText === "") && "icon-send-disabled"
                    }`}
                  />
                }
                onChange={(ev) => setInputText(ev.target.value)}
                value={inputText}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
            <p className="disclaimer">{t("pages.Quincy.disclaimer")}</p>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default Quincy;
