import { TOKEN } from "shared/constants/StorageConsts";

const fetchWithBaseUrl = (endpoint: string, options: RequestInit) => {
  return fetch(`${process.env.REACT_APP_HOST_ENDPOINT}${endpoint}`, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    },
  });
};

export const chatStreaming = (body: any) => {
  return fetchWithBaseUrl("Chat/send-message-streaming", {
    method: "POST",
    body: JSON.stringify(body),
  });
};
