import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { TOKEN } from "shared/constants/StorageConsts";
import { logout } from "shared/helpers/auth-functions";
import { getRefPageLocalStorage } from "shared/helpers/ref-page";
import { Cupom } from "shared/interfaces/Cupom";
import { ReleasesSchedule } from "shared/interfaces/ReleasesSchedule";
import { UpcomingRelease } from "shared/interfaces/UpcomingRelease";
import { Usuario } from "shared/interfaces/Usuario";
let pendingRequests = new Map();

const apisToPreventDuplicates = [
  "quiz/save",
  "user/register-user",
  "cashadvance/verify-advance-by-logged-user",
  /^\/cashadvance\/create-new\/[^/]+$/,
];

const isUrlInList = (url: string, list: Array<string | RegExp>): boolean => {
  return list.some((pattern) => {
    if (pattern instanceof RegExp) {
      return pattern.test(url);
    }
    return url.includes(pattern);
  });
};

export const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST_ENDPOINT,
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers["Authorization"] = `Bearer ${localStorage.getItem(TOKEN)}`;
  config.headers["reference"] = getRefPageLocalStorage();
  return config;
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const key = `${config.method}:${config.url}`;

  if (
    isUrlInList(config.url || "", apisToPreventDuplicates) &&
    pendingRequests.has(key)
  ) {
    throw new axios.Cancel("Operação duplicada");
  }
  if (isUrlInList(config.url || "", apisToPreventDuplicates)) {
    pendingRequests.set(key, true);
  }

  return config;
});

instance.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    if (isUrlInList(response.config.url || "", apisToPreventDuplicates)) {
      const key = `${response.config.method}:${response.config.url}`;
      pendingRequests.delete(key);
    }

    return response.data;
  },
  (error: AxiosError) => {
    if (axios.isCancel(error)) {
      console.log("Requisição cancelada:", error.message);
    } else if (error.response) {
      switch (error.response.status) {
        case 401:
        case 403:
          logout();
          break;
      }
    }
    return Promise.reject(error);
  }
);

export const fillFileRequestProps = (context: any) => {
  return {
    action: `${process.env.REACT_APP_HOST_ENDPOINT}file/upload?contextName=${context.contextName}&key=${context.key}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    },
  };
};

export const trackUserEvent = (params: any) =>
  instance.put("trackactivity/append-activity", params);

export const getLoggedUserDistroToken = () =>
  instance.get("auth/get-logged-user-distro-token");

export const fetchUserDeviceCheck = (browserId: string) =>
  instance.get(`auth/user-device-check/${browserId}`);

export const validateUserDeviceCode = (code: string, browserId: string) =>
  instance.post(`auth/user-device-validate-code/${browserId}/?code=${code}`);

export const fetchLoggedUser = (browserId: string) =>
  instance.get(`user/user-logged-info/${browserId}`);

export const fetchPersonifiedUser = () =>
  instance.get(`user/user-personified-info`);

export const updateUserPhone = (params: any) =>
  instance.put(`user/update-phone`, params);

export const fetchUserDiagnostics = () =>
  instance.get("diagnostic/user-logged-list");

export const fetchUserCredits = () =>
  instance.get("diagnostic/user-logged-credits");

export const fetchUsers = () => instance.get("user/get-users");

export const createUser = (usuario: Usuario) =>
  instance.post("user/create-user", usuario);

export const registerUser = (usuario: Usuario) =>
  instance.post("user/register-user", usuario);

export const fetchClaims = () => instance.get("auth/claims-group-list");

export const editUser = (user: Usuario) => instance.put("user/edit-user", user);

export const fetchUserById = (id: number) =>
  instance.get(`user/get-user-by/${id}`);

export const getArtistUserLogged = () =>
  instance.get("dashboard/user-logged-artist-info");

export const getArtistDashboard = (artistId: string) =>
  instance.get(`/dashboard/artist-info/${artistId}`);

export const fetchIndividualMetrics = (artistId: string) =>
  instance.get(`dashboard/artist-info-individual-metrics/${artistId}`);

export const saveLoggedUser = (user: Usuario) =>
  instance.put("user/user-logged-update-info", user);

export const putChangeLanguage = (lang: string) =>
  instance.put(`user/user-logged-update-language?userCurrentLanguage=${lang}`);

export const validateDiagnosePurchase = (paymentId: string) =>
  instance.put(`diagnostic/validate-user-diagnostic-purchase/${paymentId}`);

export const validateDiagnosePurchaceEmail = (
  paymentId: string,
  email: string
) =>
  instance.put(
    `diagnostic/validate-user-diagnostic-purchase/${paymentId}/${email}`
  );

export const fetchCountries: any = () => instance.get("quiz/country-list");

export const generateUserDiagnoseTokenUser = () =>
  instance.get("diagnostic/generate-user-diagnostic-token/user");

export const generateUserDiagnoseTokenTeam = () =>
  instance.get("diagnostic/generate-user-diagnostic-token/team");

export const fetchGenres: any = () => instance.get("quiz/genre-list");

export const fetchArtistsRelation: any = () =>
  instance.get("quiz/artist-relationship-list");

export const fetchSpotifyArtists = (
  term: string,
  country: string,
  offset: number
) => instance.get(`quiz/spotify-artist-list/${term}/${country}/${offset}`);

export const fetchYoutubeArtists = (term: string) =>
  instance.get(`quiz/youtube-artist-list/${term}`);

export const fetchInstagramUser = (term: string) =>
  instance.get(`quiz/validate-instagram-username/${term}`);

export const saveQuiz: any = (quiz: any) => instance.post("quiz/save", quiz);

export const fetchArtistQuiz: any = (id: any) =>
  instance.get(`quiz/get-quiz-artist-by-id/${id}`);

export const validateCoupon = (coupon: string) =>
  instance.put(`coupon/validate-cupom/${coupon}`);

export const fetchCoupons: any = () => instance.get("coupon/get-coupon-list");

export const createCoupon = (coupon: Cupom) =>
  instance.put("coupon/create-new-coupon", coupon);

export const disableCoupon = (params: Cupom) =>
  instance.delete("coupon/disable-coupon", { data: params });

export const fetchPainelList = () => instance.get("panel/get-panel-list");

export const subscribeNewsletter = () =>
  instance.post("user/subscribe-newsletter");

export const cancelSubscription = (params: any) =>
  instance.post("user/unsubscribe-newsletter", params);

export const getUserInvites = () => instance.get("user/user-logged-invites");

export const acceptInvites = (params: any) =>
  instance.put("diagnostic/user-diag-invites-reply/true", params);

export const rejectInvites = (params: any) =>
  instance.put("diagnostic/user-diag-invites-reply/false", params);

export const assignDiagToInvites = (idDiagnostico: string) =>
  instance.put(
    `diagnostic/assign-diag-to-all-accepted-invites/${idDiagnostico}`
  );

export const updatePurchaseInfo = (params: any) =>
  instance.put("user/update-purchase-info", params);

export const fetchCheckoutDiagnosticURL = (params: any) =>
  instance.put("diagnostic/generate-new-diag-purchase", params);

export const fetchCheckoutRegularPrice = () =>
  instance.get("diagnostic/get-diag-user-unit-price");

export const fetchCheckoutDiscountPrice = () =>
  instance.get("diagnostic/get-diag-user-unit-price-promo");

export const clearDiagnosticCache = (diagnosticId: string) =>
  instance.put(`diagnostic/clear-diag-cache/${diagnosticId}`);

export const processDiagnostic = (diagnosticId: string) =>
  instance.put(`diagnostic/reprocess-diag/${diagnosticId}`);

export const fetchAdvanceDataLoggedUser = () =>
  instance.get("cashadvance/get-advance-data-by-logged-user");

export const fetchAdvanceReleases = (advanceId: string) =>
  instance.get(`cashadvance/get-advance-releases/${advanceId}`);

export const putCalculateCashAdvanceRelease = (
  params: any,
  userArtistId: string
) =>
  instance.put(
    `cashadvance/calculate-advance-total-amount/${userArtistId}`,
    params
  );

export const putAssignCashAdvance = (params: any) =>
  instance.put("cashadvance/assign-releases", params);

export const putAssignDocumentsCashAdvance = (params: any) =>
  instance.put("cashadvance/assign-documents", params);

export const putValidateDocumentCashAdvance = (params: any) =>
  instance.put("cashadvance/validate-distro-documents", params);

export const putDisbursementCashAdvance = (params: any) =>
  instance.put("cashadvance/disbursement-data", params);

export const changeStatusCashAdvanceProcess = (id: string, status: any) =>
  instance.put(`cashadvance/change-status/${id}/${status}`);

export const createNewCashAdvance = (
  userId: string,
  specialCampaign?: string
) =>
  instance.put(
    `cashadvance/create-new${
      specialCampaign ? `/${userId}/${specialCampaign}` : `/${userId}`
    }`
  );

export const acceptCashAdvanceProposal = (cashAdvanceId: string) =>
  instance.put(`cashadvance/accepted-new-amount/${cashAdvanceId}`);

export const putAssignContractData = (params: any) =>
  instance.put(`cashadvance/assign-contract-data`, params);

export const fetchAdvanceSignedContractUrl = () =>
  instance.get(`cashadvance/contract-signed-advance-logged-user`);

export const putReprocessAdvance = (cashAdvanceId: string) =>
  instance.put(`CashAdvance/reprocess/${cashAdvanceId}`);

export const putMarkContractSignatureFirstView = (cashAdvanceId: string) =>
  instance.put(
    `cashadvance/mark-contract-signature-first-view/${cashAdvanceId}`
  );

export const downloadAdvanceSignedContract = (cashAdvanceId: string) =>
  instance.get(`cashadvance/generate-contract/${cashAdvanceId}`, {
    responseType: "arraybuffer",
  });

export const getAddressByCep = (cep: string) =>
  instance.get(`cep/get-address-by-code/${cep}`);

export const putVerifyAdvanceByLoggedUser = () =>
  instance.put(`cashadvance/verify-advance-by-logged-user`);

export const putVerifyAdvanceByLoggedUserForce = () =>
  instance.put(`cashadvance/verify-advance-by-logged-user-force`);

export const putAssignRelationship = (cashAdvanceId: string, params: any) =>
  instance.put(`cashadvance/assign-relationship/${cashAdvanceId}`, params);

export const depersonifyUser = () =>
  instance.delete(`auth/depersonify-logged-user`);

export const fecthBankList = () => instance.get(`utils/bank-list`);

export const subscribeDistro = (userArtistId: string) =>
  instance.post(`quiz/request-distribution/${userArtistId}`);

export const getUrlUpload = (params: any) =>
  instance.put(`file/generate-upload-url`, params);

export const postSignDistributionContract = (params: any) =>
  instance.put(`distribution/sign-distribution-contract`, params);

export const postSignDistributionSpecialCampaignContract = (params: any) =>
  instance.put(
    `distribution/sign-distribution-special-campaign-contract`,
    params
  );

export const getDistributionContractSpecialCampaign = () =>
  instance.get(
    `distribution/get-distribution-contract-special-campaign-logged-user`
  );

export const postSpecialCampaignRequest = (params: any) =>
  instance.post(`specialcampaignrequest`, params);

export const specialCampaignRequestConfirmNotification = (
  specialCampaignRequestId: string
) =>
  instance.put(
    `specialcampaignrequest/confirm-notification/${specialCampaignRequestId}`
  );

export const getCatalogMigrationByLoggedUser = () =>
  instance.get(`catalogmigration/get-by-logged-user`);

export const createUpcomingRelease = (params: UpcomingRelease) =>
  instance.post(`upcomingrelease`, params);

export const getRecommendationByArtistFilter = (params: any) =>
  instance.post(`recommendation/by-artist-filter`, params);

export const createUserArtistSchedule = (params: ReleasesSchedule) =>
  instance.post(`userartistschedule`, params);
