import { Checkbox, Dropdown, Input, MenuProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as MusicNote } from "shared/assets/images/music-note.svg";
import { useEffect, useMemo, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { formatNoSign, signByCurrency } from "shared/helpers/BRLFormat";
import { putAssignCashAdvance } from "shared/service/StrmService";
import { StepLancamentos } from "../AdiantamentoSelecaoLancamento";
import { stringIncludes } from "shared/helpers/remove-diacritics";

enum FilterType {
  Todos = 0,
  Albuns = 1,
  Singles = 2,
  SemFeat = 3,
  UmFeat = 4,
  DoisFeats = 5,
  TresFeatsMais = 6,
}

const EdicaoRoyalties: React.FC<any> = (props) => {
  const {
    advance,
    valorTotal,
    loadingTotal,
    selectedReleases,
    setSelectedReleases,
    reloadCashAdvance,
    setStep,
    handleCalculoRoyalties,
  } = props;
  const { t } = useTranslation();
  const [albunsExpandidos, setAlbunsExpandidos] = useState<string[]>(
    selectedReleases.map(({ upc }: any) => upc)
  );
  const [selectedTracks, setSelectedTracks] = useState<string[]>([]);
  const [filteredReleases, setFilteredReleases] = useState<any[]>([]);
  const [royaltyInputValue, setRoyaltyInputValue] = useState<string | number>(
    ""
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showStickyInput, setShowStickyInput] = useState<boolean>(false);
  const [showStickyCheckbox, setShowStickyCheckbox] = useState<boolean>(false);
  const [filterSearch, setFilterSearch] = useState<string>("");

  const toggleExpansion = (upc: string) => {
    if (albunsExpandidos.includes(upc)) {
      setAlbunsExpandidos((prev) => prev.filter((item) => item !== upc));
    } else {
      setAlbunsExpandidos((prev) => [...prev, upc]);
    }
  };

  const releases: any[] = useMemo(
    () => selectedReleases.filter((item: any) => item.selected),
    [selectedReleases]
  );

  const allTracksIDs = useMemo(() => {
    return filteredReleases.flatMap((release) =>
      release.tracks.map((track: any) => track.trackId)
    );
  }, [filteredReleases]);

  const checkMasterIndeterminate = () => {
    const selectedCount = selectedTracks.length;
    return selectedCount > 0 && selectedCount < allTracksIDs.length;
  };

  const checkMasterChecked = () => {
    return (
      selectedTracks.length === allTracksIDs.length && selectedTracks.length > 0
    );
  };

  const handleMasterCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked || checkMasterIndeterminate()) {
      setSelectedTracks(allTracksIDs);
    } else {
      setSelectedTracks([]);
    }
  };

  const selectAllReleasesTrackc = (releases: any[]) => {
    setSelectedTracks(
      releases
        .map((release) => release.tracks.map((track: any) => track.trackId))
        .flat(1)
    );
  };

  type FeatCriterion = (amountOfFeats: number) => boolean;

  const filterTracksByFeats = (criterion: FeatCriterion): any[] => {
    return releases
      .map((release) => {
        const filteredTracks = release.tracks.filter((track: any) =>
          criterion(track.amountOfFeats)
        );

        if (filteredTracks.length > 0) {
          return { ...release, tracks: filteredTracks };
        }

        return null;
      })
      .filter(Boolean);
  };

  const filtrarPorTipo = (tipoFiltro?: FilterType): void => {
    let filtered = [];
    switch (tipoFiltro) {
      case FilterType.Todos:
        filtered = releases;
        break;
      case FilterType.Albuns:
        filtered = releases.filter(
          (release) => release.releaseType === "album"
        );
        break;
      case FilterType.Singles:
        filtered = releases.filter(
          (release) => release.releaseType === "single"
        );
        break;
      case FilterType.SemFeat:
        filtered = filterTracksByFeats((feats) => feats === 0);
        break;
      case FilterType.UmFeat:
        filtered = filterTracksByFeats((feats) => feats === 1);
        break;
      case FilterType.DoisFeats:
        filtered = filterTracksByFeats((feats) => feats === 2);
        break;
      case FilterType.TresFeatsMais:
        filtered = filterTracksByFeats((feats) => feats >= 3);
        break;
      default:
        filtered = releases;
        break;
    }
    setFilteredReleases(filtered);
    selectAllReleasesTrackc(filtered);
  };

  const searchReleases = (data: any[], searchTerm: string): any[] => {
    return data
      .map((release) => {
        // Se o termo de busca está no releaseName, retorna o release inteiro
        if (stringIncludes(release.releaseName, searchTerm)) {
          return release;
        }

        // Filtra as tracks baseado no termo de busca
        const filteredTracks = release.tracks.filter((track: any) =>
          stringIncludes(track.trackName, searchTerm)
        );

        // Se houver tracks filtradas, retorna o release com essas tracks
        if (filteredTracks.length > 0) {
          return { ...release, tracks: filteredTracks };
        }

        // Retorna null se não houver correspondência
        return null;
      })
      .filter(Boolean); // Remove os itens null da lista
  };

  const checkInderteminate = (releaseTracks: any) => {
    const tracksIDs: string[] = releaseTracks.map(
      ({ trackId }: any) => trackId
    );

    const tracksInSelected = tracksIDs.filter((track) =>
      selectedTracks.includes(track)
    );

    return {
      indeterminate:
        tracksInSelected.length > 0 &&
        tracksInSelected.length < tracksIDs.length,
      checked: tracksInSelected.length === tracksIDs.length,
    };
  };

  const handleCheckboxTrackChange = (
    e: CheckboxChangeEvent,
    trackIDs: string
  ) => {
    if (e.target.checked) {
      setSelectedTracks((prevTracks) => [...prevTracks, trackIDs]);
    } else {
      setSelectedTracks((prevTracks) =>
        prevTracks.filter((track) => track !== trackIDs)
      );
    }
  };

  const handleCheckboxReleaseChange = (e: CheckboxChangeEvent, tracks: any) => {
    const tracksIDs: string[] = tracks.map(({ trackId }: any) => trackId);
    if (e.target.checked) {
      // Adicione todas as faixas que ainda não estão em selectedTracks
      const newSelectedTracks = [
        ...selectedTracks,
        ...tracksIDs.filter((track) => !selectedTracks.includes(track)),
      ];
      setSelectedTracks(newSelectedTracks);
    } else {
      // Remova todas as faixas de selectedTracks que estão em tracksIDs
      const newSelectedTracks = selectedTracks.filter(
        (track) => !tracksIDs.includes(track)
      );
      setSelectedTracks(newSelectedTracks);
    }
  };

  const handleChangeRoyailtyShare = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      setRoyaltyInputValue("");
      return;
    }

    const parsedValue = parseInt(inputValue, 10);
    if (parsedValue < 0) {
      setRoyaltyInputValue(0);
    } else if (parsedValue > 100) {
      setRoyaltyInputValue(100);
    } else {
      setRoyaltyInputValue(parsedValue);
    }
  };

  const updateSelectedReleases = () => {
    const newValue = Number(royaltyInputValue);
    const updatedReleases = selectedReleases.map((release: any) => {
      return {
        ...release,
        tracks: release.tracks.map((track: any) => {
          if (selectedTracks.includes(track.trackId)) {
            return {
              ...track,
              royaltyShare: newValue,
            };
          }
          return track;
        }),
      };
    });

    setSelectedReleases(updatedReleases);
    setSelectedTracks([]);
    handleCalculoRoyalties(updatedReleases);
  };

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: "Todos",
      onClick: () => filtrarPorTipo(FilterType.Todos),
    },
    {
      key: "1",
      label: "Álbuns",
      onClick: () => filtrarPorTipo(FilterType.Albuns),
    },
    {
      key: "2",
      label: "Singles",
      onClick: () => filtrarPorTipo(FilterType.Singles),
    },
    {
      key: "3",
      label: "Faixas sem feat",
      onClick: () => filtrarPorTipo(FilterType.SemFeat),
    },
    {
      key: "4",
      label: "Faixas com 1 feat",
      onClick: () => filtrarPorTipo(FilterType.UmFeat),
    },
    {
      key: "5",
      label: "Faixas com 2 feats",
      onClick: () => filtrarPorTipo(FilterType.DoisFeats),
    },
    {
      key: "6",
      label: "Faixas com 3 feats ou mais",
      onClick: () => filtrarPorTipo(FilterType.TresFeatsMais),
    },
  ];

  const onClickMoveForward = async () => {
    setLoading(true);
    await putAssignCashAdvance({
      id: advance.id,
      totalAmount: valorTotal,
      selectedReleases: selectedReleases,
    });
    reloadCashAdvance();
    setLoading(false);
  };

  useEffect(() => {
    setFilteredReleases(releases);
  }, [releases]);

  useEffect(() => {
    const handleScroll = () => {
      const inputOffset =
        document.getElementById("input-section")?.offsetTop || 0;
      const checkboxOffset =
        document.getElementById("checkbox-section")?.offsetTop || 0;
      const scrollPosition = window.pageYOffset;

      setShowStickyInput(
        scrollPosition > inputOffset && scrollPosition < checkboxOffset
      );
      setShowStickyCheckbox(scrollPosition >= checkboxOffset);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleSearch = debounce((value: string) => {
      const result = searchReleases(releases, value); // Usando a função searchReleases que discutimos anteriormente.
      setFilteredReleases(result);
    }, 300);
    handleSearch(filterSearch);
  }, [filterSearch, releases]);

  return (
    <div className="selecao-lancamentos">
      <div
        className="flex-column-between"
        style={{
          justifyContent: "inherit",
          maxWidth: isMobile ? "unset" : 650,
        }}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          color="#fff"
          onClick={() => setStep(StepLancamentos.Selecao)}
          className="icone-voltar"
          style={{ margin: 12, cursor: "pointer", alignSelf: "start" }}
        />
        <div className="migracao selecao-lancamento">
          <div className="texto">
            <div className="inline">
              <h1>
                <b>{t("pages.Adiantamentos.editeRoyaltiesTitulo")}</b>
              </h1>
            </div>
            <span>
              <b>{t("pages.Adiantamentos.editeRoyaltiesTexto")}</b>
            </span>

            <div id="input-section" style={{ margin: "58px 0" }}>
              <Input
                prefix={<SearchOutlined />}
                placeholder={t("pages.Adiantamentos.buscarPorLancamentoFaixa")}
                onChange={(e) => setFilterSearch(e.target.value)}
                value={filterSearch}
              />
            </div>
          </div>

          <div
            style={{
              background: "#333333",
              borderRadius: 8,
              padding: "16px 16px 64px 16px",
            }}
          >
            {showStickyInput && (
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1000,
                  background: "#333333",
                  width: "100%",
                  padding: "10px 0",
                }}
              >
                <Input
                  prefix={<SearchOutlined />}
                  placeholder={t(
                    "pages.Adiantamentos.buscarPorLancamentoFaixa"
                  )}
                  onChange={(e) => setFilterSearch(e.target.value)}
                  value={filterSearch}
                />
              </div>
            )}
            <div
              id="checkbox-section"
              style={{
                display: "flex",
                gap: 4,
                alignItems: "center",
                justifyContent: "space-between",
                position: "sticky",
                top: 50,
                background: "#333333",
                zIndex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 4,
                  marginLeft: 22,
                  background: "#333333",
                  padding: "12px 0",
                }}
              >
                <Checkbox
                  indeterminate={checkMasterIndeterminate()}
                  checked={checkMasterChecked()}
                  onChange={handleMasterCheckboxChange}
                />
                <Dropdown menu={{ items }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </Dropdown>
                <span className="quantidade-tracks">
                  {selectedTracks?.length}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#8E8E8E" }}>Royalties</span>
                <Input
                  style={{ width: 65 }}
                  className="input-branco"
                  type="number"
                  min={0}
                  max={100}
                  showCount={false}
                  value={royaltyInputValue}
                  onChange={handleChangeRoyailtyShare}
                  disabled={selectedTracks.length === 0}
                />
                <button
                  className="botao-roxo aplicar"
                  onClick={updateSelectedReleases}
                  disabled={selectedTracks.length === 0}
                >
                  {t("pages.Adiantamentos.aplicar")}
                </button>
              </div>
            </div>

            {/* Componente Sticky Checkbox "Gêmeo" */}
            {showStickyCheckbox && (
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 999,
                  background: "#333333",
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px 16px",
                  borderRadius: 8,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 4,
                    marginLeft: 22,
                  }}
                >
                  <Checkbox
                    indeterminate={checkMasterIndeterminate()}
                    checked={checkMasterChecked()}
                    onChange={handleMasterCheckboxChange}
                  />
                  <Dropdown menu={{ items }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </Dropdown>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#8E8E8E" }}>Royalties</span>
                  <Input
                    style={{ width: 65 }}
                    className="input-branco"
                    type="number"
                    min={0}
                    max={100}
                    showCount={false}
                    value={royaltyInputValue}
                    onChange={handleChangeRoyailtyShare}
                    disabled={selectedTracks.length === 0}
                  />
                  <button
                    className="botao-roxo aplicar"
                    onClick={updateSelectedReleases}
                    disabled={selectedTracks.length === 0}
                  >
                    {t("pages.Adiantamentos.aplicar")}
                  </button>
                </div>
              </div>
            )}

            {filteredReleases.map((release: any, index) => (
              <div
                style={{
                  background: "#292929",
                  flex: "100%",
                  borderRadius: 8,
                  padding: 22,
                  marginTop: 24,
                }}
                key={`RELEASE_NUMBER_${index}`}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 12 }}
                  >
                    <Checkbox
                      {...checkInderteminate(release.tracks)}
                      onChange={(e) =>
                        handleCheckboxReleaseChange(e, release.tracks)
                      }
                    />
                    <img
                      alt="album cover"
                      src={release.releaseImageUrl}
                      style={{ width: 46, height: 46 }}
                    />
                    <div style={{ alignSelf: "flex-start" }}>
                      <span style={{ display: "block" }}>
                        {t(`pages.Adiantamentos.${release.releaseType}`)}
                      </span>
                      {release.releaseName}
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    onClick={() => toggleExpansion(release.upc)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 24,
                  }}
                >
                  <span style={{ marginLeft: 34 }}>
                    {t("pages.Adiantamentos.tracks")}
                  </span>
                  <span>Royalties</span>
                </div>
                {albunsExpandidos.includes(release.upc) &&
                  release.tracks.map((track: any) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 24,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <Checkbox
                          checked={selectedTracks.includes(track.trackId)}
                          onChange={(e) =>
                            handleCheckboxTrackChange(e, track.trackId)
                          }
                        />
                        <MusicNote />
                        <div>
                          <span style={{ display: "block" }}>
                            {track.trackName}
                          </span>
                          <span style={{ color: "#8E8E8E" }}>
                            {track.artists
                              ?.map(({ artistName }: any) => artistName)
                              .join(", ")}
                          </span>
                        </div>
                      </div>
                      <span>{track.royaltyShare}%</span>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
        <div
          className="caixa-total"
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {loadingTotal || loading ? (
            <>
              <span>{t("pages.Adiantamentos.calculandoValores")}</span>
              <span style={{ minWidth: 100, textAlign: "right", height: 30 }}>
                <LoadingOutlined size={36} />
              </span>
              <button
                className="botao-branco continuar"
                style={{
                  flex: 1,
                  flexBasis: "auto",
                  justifyContent: "space-between",
                  marginTop: 6,
                  WebkitFlexBasis: "100%",
                }}
                type="button"
                disabled
              >
                {t("pages.Adiantamentos.solicitarAdiantamento")}
              </button>
            </>
          ) : (
            <>
              <span>
                {t("pages.Adiantamentos.selecioneLancamentos")}{" "}
                <b style={{ fontSize: 10 }}>
                  {t("pages.Adiantamentos.sujeitoAprovacao")}
                </b>
              </span>
              <span className="valor-total">
                <b> {signByCurrency(advance?.currency)}</b>{" "}
                {formatNoSign(valorTotal, advance?.currency)}
              </span>

              <button
                className="botao-branco continuar"
                style={{
                  flex: 1,
                  flexBasis: "auto",
                  justifyContent: "space-between",
                  WebkitFlexBasis: "100%",
                }}
                type="button"
                onClick={onClickMoveForward}
              >
                {t("pages.Adiantamentos.solicitarAdiantamento")}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default EdicaoRoyalties;
