import { EstagioArtistaProps } from "./NewEstagioArtista";
import { ReactComponent as AlertIcon } from "shared/assets/images/icon-alert-triangle.svg";
import { ReactComponent as RocketIcon } from "shared/assets/images/icon-rocket.svg";
import { ReactComponent as QuoteIcon } from "shared/assets/images/icon-aspas.svg";
import { ReactComponent as HeartIcon } from "shared/assets/images/icon-coracao.svg";
import { ReactComponent as DistroIcon } from "shared/assets/images/icon-distro-colorido.svg";
import { ReactComponent as SpotifyIcon } from "shared/assets/images/icon-spotify.svg";
import { ReactComponent as AppleMusicIcon } from "shared/assets/images/icon-apple-music.svg";
import { ReactComponent as AmazonMusicIcon } from "shared/assets/images/icon-amazon-music.svg";
import { ReactComponent as YoutubeIcon } from "shared/assets/images/icon-youtube.svg";
import { ReactComponent as DeezerIcon } from "shared/assets/images/icon-deezer.svg";
import { ReactComponent as PinkArrow } from "shared/assets/images/pink-arrow.svg";
import { ReactComponent as NewTrackDistroIcon } from "shared/assets/images/icon-distro-new-track.svg";
import { ReactComponent as TrendingUpIcon } from "shared/assets/images/icon-trending-up.svg";
import { Trans, useTranslation } from "react-i18next";
import { Progress } from "antd";
import { getI18nGenre } from "shared/helpers/i18nGenre";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import EstagioArtistaCTADiagnostico from "./EstagioArtistaCTADiagnostico";
import CardPlanoEstrategico from "./cards/CardPlanoEstrategico";
import CardDiagnosticoCompleto from "./cards/CardDiagnosticoCompleto";
import { isMobile } from "react-device-detect";
import CardDistroTier from "./cards/CardDistroTier";
import { Link, useHistory } from "react-router-dom";
import CardAdiantamento from "./cards/CardAdiantamento";
import CardDistro from "./cards/CardDistro";
import { ArtistTierStrmEnum } from "shared/enums/ArtistTierStrmEnum";
import { WhatsAppContactConsultor } from "shared/helpers/whatsapp-contact";
import { CashAdvanceAvailableEnum } from "shared/enums/CashAdvanceAvailable";
import { useEffect, useState } from "react";
import GenreTagList from "shared/components/genre-tag-list";
import { canAskAdvance } from "explorer/reducers/adiantamentos";

const EstagioArtistaMinhaCarreira: React.FC<EstagioArtistaProps> = (props) => {
  const { artistByGenre } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const getGenrePercentageAtCurrentStage = (
    currentStage: number,
    artistsStage: any
  ): number => {
    const totalArtists = artistsStage
      .filter((x: any) => x.careerStage <= currentStage)
      .map((x: any) => x.amountArtists)
      .reduce((acc: number, valor: number) => acc + valor, 0);

    const amountArtistCurrentStage = artistsStage.find(
      (x: any) => x.careerStage === currentStage
    );

    return (amountArtistCurrentStage.amountArtists / totalArtists) * 100;
  };

  const [percentageAtCurrentStage, setPercentageAtCurrentStage] = useState("");
  const { userArtist } = useSelector((state: any) => state.artists);
  const { loggedUser } = useSelector((state: any) => state.users);
  const { genres } = useSelector((state: any) => state.genres);
  const { cashAdvanceAvailable } = useSelector((state: any) => state.advances);
  let {
    titulo,
    explicacao,
    grandeDesafio,
    grandeDesafioTitulo,
    grandeDesafioText,
    momentoTexto,
    momento,
    grandeDesafioAtualPosicao,
    grandeDesafioAtual,
    grandeDesafioAtualTexto,
    primeiraInstrucaoTitulo,
    primeiraInstrucaoTexto,
  } = t(`pages.EstagioArtista.estagio.${userArtist?.stage || 0}`) as any;

  useEffect(() => {
    if (artistByGenre && artistByGenre.length && userArtist?.stage) {
      const percentage = getGenrePercentageAtCurrentStage(
        userArtist?.stage,
        artistByGenre
      );

      setPercentageAtCurrentStage(percentage.toFixed(2));
    }
  }, [artistByGenre, userArtist?.stage]);

  const { daysOfRelease, genre, isUnderAverage, percentageOfRelease } =
    userArtist?.releaseFrequency || {};

  const getCardByTier = () => {
    const tier = userArtist?.tierStrm;
    if (tier === ArtistTierStrmEnum.NotEligible) return null;
    if (tier === ArtistTierStrmEnum.StrmMusic)
      return <CardDistroTier.StrmMusic />;
    if (tier === ArtistTierStrmEnum.StrmStars) return <CardDistroTier.Stars />;
    if (tier === ArtistTierStrmEnum.StrmLegends)
      return <CardDistroTier.Legends />;
  };

  const goRouteScrollTop = (route: string) => {
    history.push(route);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const showCTASemAdiantamento = () => {
    return (
      !canAskAdvance(cashAdvanceAvailable) && !loggedUser.isCashAdvanceAvailable
    );
  };

  const mensagemSemAdiantamento = () => {
    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotAvailable)
      return t("pages.Adiantamentos.naoCumpreRequisitosMensagemSimples");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NeedRetry)
      return t("pages.Adiantamentos.tentarNovamenteEmbreve");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotFound)
      return t("pages.Adiantamentos.spotifyNaoEncontrado");
  };

  const onOkModalDistro = async () => {
    history.push("/distribua");
  };

  return (
    <>
      <div className="minha-carreira">
        <span className="page-header padding-default">
          {t("pages.EstagioArtista.visaoGeral")}
        </span>
        <div className="title-exportar padding-default">
          <h1>{t("pages.EstagioArtista.minhaCarreira")}</h1>
        </div>
        <span className="divider padding-default" />
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <div className="artista-estagio padding-default">
            <div className="caixa-imagem">
              <img src={userArtist?.profileImage} className="imagem" alt="" />
              <span className="numero">{userArtist?.stage} </span>
            </div>
            <div className="informacoes">
              <span>{t("pages.EstagioArtista.artista")}</span>
              <h1>{titulo}</h1>
            </div>
          </div>
          <p
            style={{
              margin: isMobile ? "28px 0" : "48px 0",
              fontSize: isMobile ? 14 : 16,
            }}
            className="padding-default strong-roxo"
          >
            <Trans i18nKey={explicacao} />
          </p>
        </div>
        <div className="padding-default">
          <div className="estagio-atual">
            <div className="text-box">
              <span>{t("pages.EstagioArtista.progressoProximoEstagio")}</span>
              <span>{userArtist?.stagePercentual?.toFixed(0)}%</span>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                style={{
                  height: 5,
                  width: `${userArtist?.stagePercentual}%`,
                  backgroundColor: "#F2F2F2",
                  borderRadius: "3px 0px 0px 3px",
                }}
              />
              <div
                style={{
                  height: 5,
                  width: `${100 - (userArtist?.stagePercentual || 0)}%`,
                  backgroundColor: "#24236736",
                  borderRadius: "0px 3px 3px 0px",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row-reverse",
            margin: isMobile ? "" : "54px 0",
          }}
        >
          <div style={{ flex: 1 }}>
            <div className="grande-desafio padding-default">
              <span className="label">
                {t("pages.EstagioArtista.seuGrandeDesafio")}
              </span>
              <span className="valor">{grandeDesafio}</span>
            </div>
            <div className="padding-default">
              <h1
                style={{
                  fontSize: 20,
                  color: "#F2F2F2",
                  display: "flex",
                  gap: 12,
                  alignItems: "center",
                }}
              >
                <TrendingUpIcon /> {grandeDesafioTitulo}
              </h1>
              <p style={{ fontSize: 16, color: "#F2F2F2", margin: 0 }}>
                {grandeDesafioText}
              </p>
              <Link to="/metas">
                <button className="botao-branco" style={{ marginTop: "2rem" }}>
                  {t("pages.Metas.btnMetasProximoEstagio")}
                </button>
              </Link>
            </div>
          </div>
          <div className="ranking-artistas padding-default">
            <p className="mensagem-porcentagem-estagio-atual">
              <Trans
                i18nKey="pages.EstagioArtista.msgArtistaPorcentagemEstagioAtual"
                components={{
                  highlight: <span className="destaque-cor"></span>,
                  upper: (
                    <span className="destaque-cor destaque-porcentagem"></span>
                  ),
                }}
                values={{
                  porcentagem: percentageAtCurrentStage,
                  estagioAtual: userArtist?.stage,
                  genero: getI18nGenre(genre, i18n, genres),
                }}
              />
            </p>
            {artistByGenre?.map((art, index) => {
              const atual = art.careerStage === userArtist?.stage;
              return (
                <div
                  className={`artista ${atual ? "atual" : ""}`}
                  key={`ARTISTA_${index}_RANKING`}
                >
                  <span className="quantidade">
                    {art.amountArtists} {t("pages.EstagioArtista.artistas")}
                  </span>
                  <span
                    className="estagio"
                    style={{
                      background: atual
                        ? `linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54)), url(${userArtist?.profileImage}) center/cover`
                        : "#5a57ff",
                    }}
                  >
                    {art.careerStage}
                  </span>
                  <div className="bar">
                    <div
                      style={{
                        width: `${100 - art.careerStage * 4}%`,
                      }}
                      className="content"
                    >
                      <span>
                        {t(
                          `pages.EstagioArtista.estagio.${art.careerStage}.titulo`
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            <span style={{ marginTop: 20, display: "block" }}>
              {t("pages.EstagioArtista.disclaimerRanking")}
            </span>
          </div>
        </div>
        <span className="divider padding-default" />
        <div style={{ display: "flex" }} className="padding-default">
          <div className="frequencia-lancamento">
            <span className="roxo">
              {t("pages.EstagioArtista.lancamentos")}
            </span>
            <div className="conteudo">
              <div className="conteudo-titulo">
                {isUnderAverage && <AlertIcon className="alert" />}
                <span
                  style={{
                    fontSize: 20,
                    maxWidth: isMobile ? 220 : "",
                    fontWeight: 700,
                  }}
                >
                  {t("pages.EstagioArtista.mediaLancamento")}
                </span>
                <GenreTagList genreList={[genre]} />
              </div>
              {isMobile && (
                <div className="icone-foguete">
                  <RocketIcon />
                </div>
              )}
            </div>
            <div
              className={`caixa-frequencia ${
                isUnderAverage ? "abaixo" : "acima"
              }`}
            >
              <div className="valor">
                <FontAwesomeIcon
                  icon={isUnderAverage ? faArrowDown : faArrowUp}
                />
                <h1 style={{ fontSize: 48 }}>
                  {Math.abs(percentageOfRelease)} %
                </h1>
              </div>
              <span>
                <Trans i18nKey="pages.EstagioArtista.seusLancamentos" />
                <b>
                  {isUnderAverage
                    ? t("pages.EstagioArtista.abaixoMedia")
                    : t("pages.EstagioArtista.acimaMedia")}
                </b>
              </span>
              <span className="divider" />
              <span
                style={{ display: "block", marginTop: 24, marginBottom: 12 }}
              >
                {t("pages.EstagioArtista.frequenciaLancamentos")}
              </span>
              <span>
                <Trans
                  i18nKey="pages.EstagioArtista.frequencia"
                  values={{ frequencia: daysOfRelease }}
                />
              </span>
            </div>
            <p className="dica-frequencia">
              <Trans
                i18nKey={
                  isUnderAverage
                    ? t("pages.EstagioArtista.frequenciaAbaixoMedia")
                    : t("pages.EstagioArtista.frequenciaAcimaMedia")
                }
              />
            </p>
          </div>
          {!isMobile && (
            <div
              className="icone-box"
              style={{ marginTop: !isMobile ? 114 : "" }}
            >
              <div className="icone">
                <RocketIcon />
              </div>
            </div>
          )}
        </div>
        <div
          className="caixa-cta"
          style={{ display: "flex", flexDirection: "column", gap: 32 }}
        >
          {showCTASemAdiantamento() && (
            <div className="distro-cta">
              <div className="adiantamento">
                <div className="conteudo">
                  <RocketIcon className="rocket" />
                  <h1>
                    {t("pages.EstagioArtista.adiantamentoNaoLiberadoTitulo")}
                  </h1>
                  {isMobile && (
                    <div className="header flex">
                      <div className="imagem">
                        <PinkArrow />
                      </div>
                      <NewTrackDistroIcon />
                    </div>
                  )}
                  <span style={{ textAlign: "left" }}>
                    <Trans i18nKey={mensagemSemAdiantamento()} />
                  </span>
                  <button
                    className="botao-branco"
                    onClick={() => goRouteScrollTop("/adiantamentos")}
                  >
                    {t("pages.EstagioArtista.saibaMais")}
                  </button>
                </div>
                {!isMobile && (
                  <div className="header flex">
                    <div className="imagem">
                      <PinkArrow />
                    </div>
                    <NewTrackDistroIcon />
                  </div>
                )}
              </div>
            </div>
          )}

          {userArtist?.distributionAvailable && (
            <div className="distro-cta">
              <div className="chamada">
                <div className="header">
                  <DistroIcon style={{ marginTop: 20 }} />
                  <span>
                    {t("pages.EstagioArtista.distoLiberadoTituloSimples")}
                  </span>
                  <div className="imagem">
                    <PinkArrow />
                  </div>
                  <div className="lojas">
                    <SpotifyIcon />
                    <AppleMusicIcon />
                    <AmazonMusicIcon />
                    <YoutubeIcon />
                    <DeezerIcon />
                  </div>
                </div>
                <div className="textos">
                  <p>{t("pages.EstagioArtista.distroLiberadoTexto")}</p>
                  <ul style={{ padding: 0 }}>
                    <li className="circle-checkmark azul">
                      {t("pages.EstagioArtista.distroAcessoAoLabels")}
                    </li>
                    <li className="circle-checkmark azul">
                      {t("pages.EstagioArtista.distroIsencaoTaxaUpload")}
                    </li>
                    <li className="circle-checkmark azul">
                      {t("pages.EstagioArtista.distrosMigracaoCatalogo")}
                    </li>
                  </ul>
                  <div style={{ display: "flex", gap: 16 }}>
                    {!userArtist?.distributionSigned && (
                      <button
                        className="botao-branco"
                        onClick={onOkModalDistro}
                      >
                        {t("pages.EstagioArtista.distribuaComStrm")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {getCardByTier()}
            </div>
          )}
        </div>
        <div className="desafio padding-default">
          <div className="content">
            <QuoteIcon className="roxo" />
            <h1>{grandeDesafioAtualPosicao}</h1>
            <h2>{grandeDesafioAtual}</h2>
            <p>{grandeDesafioAtualTexto}</p>
          </div>
          <div className="icone-box">
            <div className="icone">
              <HeartIcon />
            </div>
          </div>
        </div>
        <span className="divider padding-default" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: isMobile ? "" : 500,
          }}
          className="padding-default"
        >
          <div style={{ flex: 1 }}>
            <div className="start">
              <div className="content">
                <span style={{ textTransform: "uppercase" }}>START</span>
                <h1>{primeiraInstrucaoTitulo}</h1>
                <p className="strong-roxo">
                  <Trans i18nKey={primeiraInstrucaoTexto} />
                </p>
              </div>
              {isMobile && (
                <div className="icone-box">
                  <div className="icone">
                    <HeartIcon />
                  </div>
                </div>
              )}
            </div>
            <div className="principais-desafios">
              <div className="box">{momentoTexto}</div>
              {momento?.map((texto: string, index: number) => (
                <div className="box" key={`box_text_${index}`}>
                  <span className="circulo-roxo" />
                  <span style={{ display: "flex", flex: 1 }}>{texto}</span>
                </div>
              ))}
            </div>
          </div>
          {!isMobile && (
            <div className="icone-box">
              <div className="icone">
                <HeartIcon />
              </div>
            </div>
          )}
        </div>
        <span className="divider padding-default" />
        <div
          className="apresentacao-diagnostico padding-default"
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row-reverse",
            alignItems: isMobile ? "unset" : "center",
          }}
        >
          <div style={{ flex: 1, display: "grid" }}>
            <EstagioArtistaCTADiagnostico />
          </div>
          <div style={{ flex: 1 }}>
            <h1 className="titulo">
              {t("pages.Diagnosticos.PreviaDiagnostico.setePorcento")}
            </h1>
            <Progress
              percent={7}
              trailColor="#cdccff1a"
              strokeColor="#5a57ff"
              strokeWidth={17}
            />
            <p style={{ fontSize: 16 }}>
              <Trans i18nKey="pages.Diagnosticos.explicacao" />
            </p>
            <div className="botoes">
              <button
                className="botao-roxo"
                onClick={() => goRouteScrollTop("/checkout-diagnostico")}
              >
                {t(
                  "pages.Diagnosticos.PreviaDiagnostico.fazerDiagnosticoCompleto"
                )}
              </button>
              <button className="botao-vazado">
                <a
                  target="_blank"
                  href={WhatsAppContactConsultor}
                  rel="noopener noreferrer"
                >
                  {t(
                    "pages.Diagnosticos.PreviaDiagnostico.falarComEspecialista"
                  )}
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="aprofunde-diagnostico">
        <div style={{ marginBottom: 32 }}>
          <span>
            {t("pages.EstagioArtista.continueLendo")}{" "}
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </div>
        <div className="cards">
          <CardPlanoEstrategico />
          <CardDistro />
          <CardAdiantamento />
          <CardDiagnosticoCompleto />
        </div>
      </div>
    </>
  );
};

export default EstagioArtistaMinhaCarreira;
