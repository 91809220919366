import { Divider, Form, Input, message, Radio, Space } from "antd";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import MeuAdiantamentoHeader from "../MeuAdiantamentoHeader";
import MeuAdiantamento from "../MeuAdiantamento";
import { CashAdvance } from "shared/interfaces/CashAdvance";
import { ReactComponent as AlertIcon } from "shared/assets/images/icon-alert-triangle.svg";
import { useTranslation } from "react-i18next";
import { ProjectRelationshipEnum } from "shared/enums/ProjectRelationship";
import { putAssignRelationship } from "shared/service/StrmService";

interface Props {
  advance: CashAdvance;
  onAvancaRelacao: (relacaoProjeto: ProjectRelationshipEnum) => void;
}

const ContratoAssinaturaRelacao: React.FC<Props> = (props) => {
  const { advance, onAvancaRelacao } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [showMeuAdiantamento, setShowMeuAdiantamento] =
    useState<boolean>(false);
  const emailResponsavel = Form.useWatch("emailResponsavel", form);
  const relacaoProjeto = Form.useWatch("relacaoProjeto", form);

  const onFinishAvancaRelacao = async () => {
    const values = form.getFieldsValue();
    if (advance?.id) {
      const params = {
        relationship: values.relacaoProjeto,
        responsiblePersonEmail: values.emailResponsavel,
      };
      try {
        await putAssignRelationship(advance.id, params);
      } catch (error) {
        message.error(t("pages.Adiantamentos.erroRelacaoProjeto"));
        return;
      }
    }
    onAvancaRelacao(values.relacaoProjeto);
  };

  return (
    <Form form={form} onFinish={onFinishAvancaRelacao} name="responsavel">
      <div
        className="contrato-assinatura"
        style={{
          display: "flex",
          marginLeft: isMobile ? "unset" : 80,
          minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
        }}
      >
        <div className="flex-column-between">
          <div className="migracao envio-relatorio">
            <MeuAdiantamentoHeader
              setShowMeuAdiantamento={setShowMeuAdiantamento}
              showMeuAdiantamento={showMeuAdiantamento}
            />
            <Divider />

            {showMeuAdiantamento ? (
              <MeuAdiantamento advance={advance} />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ maxWidth: isMobile ? "unset" : 650 }}>
                  <div className="icone-alerta">
                    <AlertIcon />
                  </div>
                  <div className="conteudo">
                    <h3 style={{ marginBottom: 32 }}>
                      {t("pages.Adiantamentos.informeRelacaoProjeto")}
                    </h3>
                    <Form.Item name="relacaoProjeto">
                      <Radio.Group
                        onChange={(event) => {
                          if (
                            event.target.value !==
                            ProjectRelationshipEnum.TeamMemberNoPowers
                          ) {
                            form.setFieldValue("emailResponsavel", "");
                          }
                        }}
                      >
                        <Space direction="vertical">
                          <Radio
                            value={
                              ProjectRelationshipEnum.ArtistOrResponsibleWithPowers
                            }
                          >
                            {t("pages.Adiantamentos.souArtista")}
                          </Radio>
                          <Radio
                            value={ProjectRelationshipEnum.NoPowersJustTesting}
                          >
                            {t("pages.Adiantamentos.semPoderesSomenteTestando")}
                          </Radio>
                          <Radio
                            value={ProjectRelationshipEnum.TeamMemberNoPowers}
                          >
                            {t("pages.Adiantamentos.teamMember")}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      label={t("pages.Adiantamentos.emailResponsavel")}
                      layout="vertical"
                      name="emailResponsavel"
                      style={{ marginTop: 24 }}
                      rules={[
                        {
                          required:
                            relacaoProjeto ===
                            ProjectRelationshipEnum.TeamMemberNoPowers,
                          message: t(
                            "pages.Adiantamentos.emailResponsavelRequired"
                          ),
                        },
                        {
                          type: "email",
                          message: t("pages.CadastreSe.emailValido"),
                        },
                      ]}
                    >
                      <Input
                        disabled={
                          relacaoProjeto !==
                          ProjectRelationshipEnum.TeamMemberNoPowers
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="footer black">
            <button
              disabled={
                relacaoProjeto === undefined ||
                (relacaoProjeto ===
                  ProjectRelationshipEnum.TeamMemberNoPowers &&
                  !emailResponsavel)
              }
              className="botao-roxo continuar"
            >
              {t("pages.Adiantamentos.avancar")}
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ContratoAssinaturaRelacao;
