import { Switch } from "react-router-dom";
import { RotaCustomizada } from "shared/components";
import LayoutAutenticado from "shared/layout/LayoutAutenticado";
import LayoutNaoAutenticado from "shared/layout/LayoutNaoAutenticado";
import CadastreSe from "explorer/pages/cadastre-se/CadastreSe";
import CheckoutDiagnostico from "explorer/pages/checkout-diagnostico/CheckoutDiagnostico";
import Diagnostico from "explorer/pages/diagnostico/Diagnostico";
import Diagnosticos from "explorer/pages/diagnosticos/Diagnosticos";
import Home from "explorer/pages/home";
import Login from "explorer/pages/login/Login";
import MeusDados from "explorer/pages/meus-dados/MeusDados";
import NotFound from "shared/pages/not-found/NotFound";
import NovoDiagnostico from "explorer/pages/novo-diagnostico/NovoDiagnostico";
import ResetSenha from "explorer/pages/reset-senha/ResetSenha";
import VerificacaoLogin from "explorer/pages/verificacao-login/VerificacaoLogin";
import LayoutQuiz from "shared/layout/LayoutQuiz";
import LayoutEstagio from "shared/layout/LayoutEstagio";
import Unsubscribe from "shared/pages/unsubscribe/Unsubscribe";
import Convites from "explorer/pages/convites/Convites";
import AceiteConvite from "explorer/pages/aceite-convite/AceiteConvite";
import EasyCheckout from "explorer/pages/easy-checkout/EasyCheckout";
import LayoutBranco from "shared/layout/LayoutBranco";
import Adiantamentos from "explorer/pages/adiantamentos/Adiantamentos";
import LayoutAdiantamento from "shared/layout/LayoutAdiantamentos";
import ConfirmacaoCheckout from "explorer/pages/confirmacao-checkout/ConfirmacaoCheckout";
import Afiliates from "shared/pages/afiliates/Afiliates";
import NewLayout from "shared/layout/NewLayout";
import Quiz from "explorer/pages/quiz/Quiz";
import NewEstagioArtista from "explorer/pages/new-estagio-artista/NewEstagioArtista";
import Distribua from "explorer/pages/distribua/Distribua";
import Metas from "explorer/pages/metas/Metas";
import Pitching from "explorer/pages/pitching";
import RecomendacaoCollab from "explorer/pages/recomentacao-collab/RecomendacaoCollab";
import ReleasesSchedule from "explorer/pages/releases-schedule/ReleasesSchedule";
import Quincy from "explorer/pages/Quincy/Quincy";

const Routes = () => {
  return (
    <Switch>
      {/* EXPLORER */}
      <RotaCustomizada path="/" exact component={Home} layout={NewLayout} />
      <RotaCustomizada
        path="/:lang?/adiantamentos"
        exact
        component={Adiantamentos}
        layout={LayoutAdiantamento}
      />
      <RotaCustomizada
        path="/:lang?/pitching"
        exact
        component={Pitching}
        layout={LayoutAdiantamento}
      />
      <RotaCustomizada
        path="/:lang?/releases-schedule"
        exact
        component={ReleasesSchedule}
        layout={LayoutAdiantamento}
      />
      <RotaCustomizada
        path="/:lang?/metas"
        exact
        component={Metas}
        layout={LayoutAdiantamento}
      />
      <RotaCustomizada
        path="/:lang?/recomendacoes"
        exact
        component={RecomendacaoCollab}
        layout={LayoutAdiantamento}
      />
      <RotaCustomizada
        path="/:lang?/easy-checkout"
        exact
        component={EasyCheckout}
        layout={LayoutBranco}
        autenticacaoNecessaria={false}
      />
      <RotaCustomizada
        path="/:lang?/confirmacao"
        exact
        component={ConfirmacaoCheckout}
        layout={LayoutAutenticado}
        autenticacaoNecessaria={false}
      />
      <RotaCustomizada
        path="/:lang?/estagio"
        exact
        component={NewEstagioArtista}
        layout={LayoutAutenticado}
      />
      <RotaCustomizada
        path="/:lang?/convites"
        exact
        component={Convites}
        layout={LayoutAutenticado}
      />

      <RotaCustomizada
        path="/:lang?/aceite-convite"
        exact
        component={AceiteConvite}
        layout={LayoutAutenticado}
      />
      <RotaCustomizada
        path="/:lang?/diagnosticos"
        exact
        component={Diagnosticos}
        layout={NewLayout}
      />
      <RotaCustomizada
        path="/:lang?/diagnostico"
        exact
        component={Diagnostico}
        layout={LayoutEstagio}
      />
      <RotaCustomizada
        path="/:lang?/diagnostico-carreira/:id"
        exact
        component={Diagnostico}
        layout={LayoutEstagio}
      />
      <RotaCustomizada
        path="/:lang?/novo-diagnostico"
        exact
        component={NovoDiagnostico}
        layout={LayoutEstagio}
      />
      <RotaCustomizada
        path="/:lang?/checkout-diagnostico"
        exact
        component={CheckoutDiagnostico}
        layout={LayoutBranco}
      />
      <RotaCustomizada
        path="/:lang?/distribua"
        exact
        component={Distribua}
        layout={LayoutAdiantamento}
      />
      <RotaCustomizada
        path="/:lang?/meus-dados"
        exact
        component={MeusDados}
        autenticacaoNecessaria={true}
        layout={LayoutAutenticado}
      />
      <RotaCustomizada
        path="/:lang?/login"
        exact
        component={Login}
        autenticacaoNecessaria={false}
        layout={LayoutNaoAutenticado}
      />
      <RotaCustomizada
        path="/:lang?/reset-senha"
        exact
        component={ResetSenha}
        autenticacaoNecessaria={false}
        layout={LayoutNaoAutenticado}
      />
      <RotaCustomizada
        path="/:lang?/cadastre"
        exact
        component={CadastreSe}
        autenticacaoNecessaria={false}
        layout={LayoutNaoAutenticado}
      />
      <RotaCustomizada
        path="/:lang?/verificacao"
        exact
        component={VerificacaoLogin}
        autenticacaoNecessaria={false}
        layout={LayoutNaoAutenticado}
      />

      {/* QUIZ */}
      <RotaCustomizada
        path="/:lang?/quiz"
        exact
        component={Quiz}
        autenticacaoNecessaria={false}
        layout={LayoutQuiz}
      />

      <RotaCustomizada
        path="/:lang?/unsubscribe/:id"
        exact
        component={Unsubscribe}
        layout={LayoutNaoAutenticado}
      />

      <RotaCustomizada
        path="/:lang?/promo/:id"
        exact
        component={Afiliates}
        layout={LayoutNaoAutenticado}
        autenticacaoNecessaria={false}
      />

      <RotaCustomizada
        path="/:lang?/quincy"
        exact
        component={Quincy}
        layout={LayoutAdiantamento}
      />

      {/* COMPARTILHADA */}
      <RotaCustomizada
        component={NotFound}
        autenticacaoNecessaria={false}
        layout={LayoutNaoAutenticado}
      />
    </Switch>
  );
};

export default Routes;
