import { Collapse } from "antd";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as RecebaAdiantamentoIcon } from "shared/assets/images/receba-adiantamento-icon.svg";
import { ReactComponent as MigracaoCatalogoIcon } from "shared/assets/images/migracao-catalogo-icon.svg";
import { ReactComponent as AssinaturaDigitalIcon } from "shared/assets/images/assinatura-digital-icon.svg";
import { changeStatusCashAdvanceProcess } from "shared/service/StrmService";
import { AdvanceStatus } from "shared/enums/AdvanceStatus";
import MeuAdiantamento from "./MeuAdiantamento";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";
import { useSelector } from "react-redux";

export enum StepAceite {
  Assinatura = 0,
  Migracao = 1,
  Recebimento = 2,
}

const AdiantamentoAceite: React.FC<any> = (props) => {
  const { advance, reloadCashAdvance } = props;
  const [showSteps, setShowSteps] = useState<boolean>(false);
  const [step, setStep] = useState<number>(StepAceite.Assinatura);

  const { t } = useTranslation();
  const faqList: any[] = t("pages.Adiantamentos.faqAceite");
  const { userArtist } = useSelector((state: any) => state.artists);

  const getStyleByStep = (propStep: StepAceite) => {
    if (step !== undefined) {
      switch (propStep) {
        case StepAceite.Migracao:
          if (step > StepAceite.Assinatura) return "complete";
          return "waiting";

        case StepAceite.Recebimento:
          if (step > StepAceite.Migracao) return "complete";
          return "waiting";
      }
    }
    return "";
  };

  const moveStep = async () => {
    dataLayerEvent("ClickButton", {
      ...quizEvents.S4A_ADVANCE_CONFIRMATION,
      buttonName: "NEXT",
      carreerStage: userArtist?.stage,
      proposalValue: advance?.totalAmountUsd,
      approvedLimit: userArtist?.cashAdvanceEstimated?.amountUsd,
      alreadyCalculated:
        userArtist?.cashAdvanceEstimated?.successfullyEstimated,
      country: userArtist?.country,
    });
    await changeStatusCashAdvanceProcess(
      advance.id,
      AdvanceStatus.DocumentationForContract
    );
    reloadCashAdvance();
  };

  const moveNext = () => {
    if (step === StepAceite.Recebimento) moveStep();
    else setStep(step + 1);
  };

  const movePrev = () => {
    if (step === StepAceite.Assinatura) {
      setShowSteps(false);
    } else {
      setStep(step - 1);
    }
  };

  const onClickBackStep = async () => {
    await changeStatusCashAdvanceProcess(
      advance.id,
      AdvanceStatus.ReleasesSelection
    );
    reloadCashAdvance();
  };

  if (showSteps)
    return (
      <>
        <div className="aceite-adiantamento">
          <div
            className="flex-column-between"
            style={{ maxWidth: isMobile ? "unset" : 650 }}
          >
            <div style={{ marginTop: 16, flex: 1 }}>
              <div className="stepper" style={{ marginBottom: 16 }}>
                <div className="step complete">
                  <div className="step-circle">
                    <div className="handler" />
                  </div>
                </div>
                <div className={`step ${getStyleByStep(StepAceite.Migracao)}`}>
                  <div className="step-circle">
                    <div className="handler" />
                  </div>
                </div>
                <div
                  className={`step ${getStyleByStep(StepAceite.Recebimento)}`}
                >
                  <div className="step-circle">
                    <div className="handler" />
                  </div>
                </div>
              </div>

              <h3 style={{ color: "#fff", textAlign: "center" }}>
                {t("pages.Adiantamentos.proximosPassos")}
              </h3>
              {step === StepAceite.Assinatura && (
                <div className="carousel-content">
                  <AssinaturaDigitalIcon />
                  <h1>{t("pages.Adiantamentos.assinarDigitalmente")}</h1>

                  <p className="text">
                    <Trans
                      i18nKey={t(
                        "pages.Adiantamentos.enviarRelatorioReceitaPassoDois"
                      )}
                    />
                  </p>
                </div>
              )}
              {step === StepAceite.Migracao && (
                <>
                  <div className="carousel-content">
                    <MigracaoCatalogoIcon />
                    <h1>{t("pages.Adiantamentos.migrarCatalogo")}</h1>

                    <p className="text">
                      <Trans
                        i18nKey={t(
                          "pages.Adiantamentos.enviarRelatorioReceitaPassoTres"
                        )}
                      />
                    </p>
                  </div>
                </>
              )}
              {step === StepAceite.Recebimento && (
                <div>
                  <div className="carousel-content">
                    <RecebaAdiantamentoIcon />
                    <h1>{t("pages.Adiantamentos.receberAdiantamento")}</h1>

                    <p className="text">
                      <Trans
                        i18nKey={t(
                          "pages.Adiantamentos.enviarRelatorioReceitaPassoQuatro"
                        )}
                      />
                    </p>
                  </div>
                </div>
              )}
            </div>
            {step === StepAceite.Migracao && (
              <div
                style={{
                  margin: isMobile ? "50px 18px 0 18px" : "50px 0 125px 0",
                  textAlign: "center",
                }}
              >
                <p>
                  <strong style={{ color: "#5A57FF" }}>*</strong>{" "}
                  {t("pages.Adiantamentos.migracaoDisclaimer")}
                </p>
                <Collapse
                  bordered={false}
                  className="fake-collapse"
                  expandIcon={() => <FontAwesomeIcon icon={faChevronDown} />}
                  expandIconPosition="end"
                >
                  <Collapse.Panel
                    header={
                      <span
                        style={{
                          display: "block",

                          color: "#F2F2F2",
                        }}
                      >
                        {t("pages.Adiantamentos.faqTitle")}
                      </span>
                    }
                    key={1}
                  >
                    <Collapse
                      bordered={false}
                      className="faq"
                      expandIcon={() => (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                      expandIconPosition="end"
                    >
                      {faqList?.map((faq, i) => (
                        <Collapse.Panel
                          header={t(`pages.Adiantamentos.faq.${faq}.pergunta`)}
                          key={i}
                        >
                          <p>
                            <Trans
                              i18nKey={t(
                                `pages.Adiantamentos.faq.${faq}.resposta`
                              )}
                            />
                          </p>
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  </Collapse.Panel>
                </Collapse>
              </div>
            )}
            {step === StepAceite.Recebimento && (
              <div
                style={{
                  margin: isMobile ? "50px 18px 0 18px" : "50px 0 125px 0",
                  textAlign: "center",
                }}
              >
                <h3>{t(`pages.Adiantamentos.faq.takedown.pergunta`)}</h3>
                <p>
                  {" "}
                  <strong style={{ color: "#5A57FF" }}>*</strong>{" "}
                  {t(`pages.Adiantamentos.faq.takedown.resposta`)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="footer black" style={{ paddingLeft: 0 }}>
          <div
            style={{
              display: "flex",
              width: isMobile ? "100%" : 650,
              flexDirection: "column",
              gap: 12,
            }}
          >
            <button className="botao-roxo continuar" onClick={moveNext}>
              {t("pages.Adiantamentos.continuar")}
            </button>
            <button className="botao-borda-roxa" onClick={movePrev}>
              {t("pages.Adiantamentos.voltar")}
            </button>
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className="aceite-adiantamento">
        <div
          className="flex-column-between"
          style={{
            maxWidth: isMobile ? "unset" : 650,
            paddingTop: isMobile ? 0 : 60,
          }}
        >
          <div style={{ paddingBottom: isMobile ? 0 : 64, marginBottom: 50 }}>
            <div className="confirma-adiantamento-intro">
              <h1>{t("pages.Adiantamentos.valoresAlteradosTitulo")}</h1>
              <p
                style={{ maxWidth: isMobile ? "unset" : 550, marginBottom: 48 }}
              >
                <Trans
                  i18nKey={t("pages.Adiantamentos.valoresAlteradosTexto")}
                />
              </p>
            </div>
            <MeuAdiantamento advance={advance} />
            <div
              style={{
                margin: isMobile ? "0 18px" : 0,
              }}
            >
              <span
                style={{ color: "#F2F2F2", display: "block", marginBottom: 12 }}
              >
                {t("pages.Adiantamentos.valoresAte")}{" "}
                <span style={{ color: "#B3B3B3" }}>
                  {moment(advance.validUntil).format("DD/MM/YYYY")}
                </span>
              </span>

              {/* <p className="disclaimer">
                <Trans
                  i18nKey={t("pages.Adiantamentos.valoresAlteradosTexto")}
                />
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer black" style={{ paddingLeft: 0 }}>
        <div
          style={{
            display: "flex",
            width: isMobile ? "100%" : 650,
            flexDirection: "column",
            gap: 12,
          }}
        >
          <button
            className="botao-roxo continuar"
            onClick={() => setShowSteps(true)}
            style={{ width: "100%" }}
          >
            {t("pages.Adiantamentos.continuar")}
          </button>
          <button
            className="botao-borda-roxa "
            onClick={onClickBackStep}
            style={{ width: "100%" }}
          >
            {t("pages.Adiantamentos.voltar")}
          </button>
        </div>
      </div>
    </>
  );
};

export default AdiantamentoAceite;
