import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";

export const TypingEffect = ({
  fullText,
  minSpeed = 5,
  maxSpeed = 25,
  onComplete,
}: any) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (isComplete) {
      console.log("completou");
      onComplete();
    }
  }, [isComplete, onComplete]);

  useEffect(() => {
    let currentIndex = 0;

    const typeLetter = () => {
      if (currentIndex < fullText.length) {
        setDisplayedText((prev) => prev + fullText[currentIndex]);
        currentIndex += 1;

        const randomSpeed =
          Math.floor(Math.random() * (maxSpeed - minSpeed + 1)) + minSpeed;
        setTimeout(typeLetter, randomSpeed);
      } else {
        setIsComplete(true);
      }
    };

    typeLetter();

    return () => {
      currentIndex = fullText.length;
    };
  }, [fullText, minSpeed, maxSpeed]);

  return <Markdown>{displayedText}</Markdown>;
};
