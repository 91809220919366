import { Checkbox, Divider, Form, Input, Select } from "antd";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MaskInput } from "shared/components";
import { formCNPJValidator } from "shared/helpers/cnpj-validator";
import { formCPFValidator } from "shared/helpers/cpf-validator";
import {
  fecthBankList,
  getAddressByCep,
  putAssignContractData,
} from "shared/service/StrmService";
import Duvidas from "./Duvidas";
import MeuAdiantamento from "./MeuAdiantamento";
import MeuAdiantamentoHeader from "./MeuAdiantamentoHeader";
import { pixKeyTypeList } from "shared/enums/PixKeyTypeEnum";
import useFetchData from "shared/hooks/useFetchData";
import { filterOptionDiacritics } from "shared/components/single-multiselect/SingleMultiselect";
import { isMobile } from "react-device-detect";
import { ReactComponent as AlertIcon } from "shared/assets/images/icon-alert-triangle.svg";
import { ReactComponent as PlayChartUpIcon } from "shared/assets/images/play-chart-up-icon.svg";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CashAdvance } from "shared/interfaces/CashAdvance";
import { ProjectRelationshipEnum } from "shared/enums/ProjectRelationship";
import { useHistory } from "react-router-dom";
import ContratoAssinaturaRelacao from "./dados-contrato/ContratoAssinaturaRelacao";

enum TipoPessoa {
  Fisica = 0,
  Juridica = 1,
}

enum StepContrato {
  Aceite = "Aceite",
  Relacao = "Relacao",
  Agradecimento = "Agradecimento",
  Contrato = "Contrato",
}

interface AdiantamentoContratoAssinaturaProps {
  advance: CashAdvance;
  reloadCashAdvance: () => void;
}

export const distroListContrato: string[] = [
  "Altafonte",
  "Believe",
  "CD Baby",
  "CmdShft",
  "DistroKid",
  "Ditto",
  "ONErpm",
  "Outra distribuidora",
  "SoundOn",
  "Symphonic",
  "Tratore",
  "Lujo Network",
];

const AdiantamentoContratoAssinatura: React.FC<
  AdiantamentoContratoAssinaturaProps
> = (props) => {
  const { advance, reloadCashAdvance } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const loggedUser = useSelector((state: any) => state.users.loggedUser);
  const [form] = Form.useForm();
  const [showMeuAdiantamento, setShowMeuAdiantamento] =
    useState<boolean>(false);
  const [tipoPessoa, setTipoPessoa] = useState<TipoPessoa>(TipoPessoa.Fisica);
  const [step, setStep] = useState<StepContrato>(StepContrato.Relacao);

  const [disabledSave, setDisabledSave] = useState(true);
  const [checkBoxes, setCheckBoxes] = useState<{
    noAdvance: false;
    noContract: false;
  }>({
    noAdvance: false,
    noContract: false,
  });
  const [bankOptions, bankLoading] = useFetchData(
    fecthBankList,
    [],
    useCallback(
      (data) =>
        data.map((item: any) => ({
          ...item,
          label: `${item?.value} - ${item?.label}`,
        })),
      []
    )
  );

  const distroOptions = useMemo(
    () => [
      ...distroListContrato.map((item) => ({
        label: item,
        value: item,
      })),
      {
        label: t("pages.Adiantamentos.naoPossuoDistribuidora"),
        value: "no-distro",
      },
    ],
    [t]
  );

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };

  const onFinish = async () => {
    setDisabledSave(true);
    const values = form.getFieldsValue(true);
    const request = {
      cashAdvanceId: advance.id,
      ...values,
    };

    await putAssignContractData(request);
    reloadCashAdvance();
  };

  const onCheckboxChange = (event: CheckboxChangeEvent) => {
    const { name = "", checked } = event.target;
    setCheckBoxes((prevState: any) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const onAvancaRelacao = async (relacaoProjeto: ProjectRelationshipEnum) => {
    if (
      relacaoProjeto === ProjectRelationshipEnum.ArtistOrResponsibleWithPowers
    ) {
      setStep(StepContrato.Contrato);
    }
    if (
      relacaoProjeto === ProjectRelationshipEnum.NoPowersJustTesting ||
      relacaoProjeto === ProjectRelationshipEnum.TeamMemberNoPowers
    ) {
      setStep(StepContrato.Agradecimento);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [loggedUser, form]);

  useEffect(() => {
    if (advance && advance.projectRelationship !== null) {
      setStep(StepContrato.Contrato);
    }
  }, [advance]);

  if (step === StepContrato.Aceite) {
    return (
      <div
        className="contrato-assinatura"
        style={{
          display: "flex",
          marginLeft: isMobile ? "unset" : 80,
          minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
        }}
      >
        <div className="flex-column-between">
          <div className="migracao envio-relatorio">
            <MeuAdiantamentoHeader
              setShowMeuAdiantamento={setShowMeuAdiantamento}
              showMeuAdiantamento={showMeuAdiantamento}
            />
            <Divider />

            {showMeuAdiantamento ? (
              <MeuAdiantamento advance={advance} />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ maxWidth: isMobile ? "unset" : 650 }}>
                  <div className="icone-alerta">
                    <AlertIcon />
                  </div>
                  <div className="conteudo">
                    <h3>{t("pages.Adiantamentos.antesDeSeguir")}</h3>
                    <div className="checkbox">
                      <Checkbox
                        name="noAdvance"
                        checked={checkBoxes.noAdvance}
                        onChange={onCheckboxChange}
                      >
                        <span>
                          {t("pages.Adiantamentos.naoPossuoOutroAdiantamento")}
                        </span>
                      </Checkbox>
                    </div>
                    <div className="checkbox">
                      <Checkbox
                        name="noContract"
                        checked={checkBoxes.noContract}
                        onChange={onCheckboxChange}
                      >
                        <span>
                          {t("pages.Adiantamentos.naoPossuoContrato")}
                        </span>
                      </Checkbox>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="footer black">
            <button
              disabled={!checkBoxes.noAdvance || !checkBoxes.noContract}
              className="botao-roxo continuar"
              onClick={() => setStep(StepContrato.Relacao)}
            >
              {t("pages.Adiantamentos.avancar")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (step === StepContrato.Relacao) {
    return (
      <ContratoAssinaturaRelacao
        advance={advance}
        onAvancaRelacao={onAvancaRelacao}
      />
    );
  }

  if (step === StepContrato.Agradecimento) {
    return (
      <div
        className="contrato-assinatura"
        style={{
          display: "flex",
          marginLeft: isMobile ? "unset" : 80,
          minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
        }}
      >
        <div className="flex-column-between">
          <div className="migracao envio-relatorio">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ maxWidth: isMobile ? "unset" : 650 }}>
                <div
                  className="conteudo"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 20,
                  }}
                >
                  <PlayChartUpIcon />
                  <h1>{t("pages.Adiantamentos.agradecimentoTitulo")}</h1>
                  <p style={{ textAlign: "center" }}>
                    <Trans
                      i18nKey={t("pages.Adiantamentos.agradecimentoTexto")}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer black">
            <button className="botao-vazado" onClick={() => history.push("/")}>
              {t("pages.Adiantamentos.irParaHome")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="contrato-assinatura"
      style={{
        marginLeft: isMobile ? "unset" : 80,
        minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
      }}
    >
      <div className="flex-column-between">
        <div className="migracao envio-relatorio">
          <MeuAdiantamentoHeader
            setShowMeuAdiantamento={setShowMeuAdiantamento}
            showMeuAdiantamento={showMeuAdiantamento}
          />
          <Divider />

          {showMeuAdiantamento ? (
            <MeuAdiantamento advance={advance} />
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ maxWidth: isMobile ? "unset" : 650 }}>
                <div className="confirma-adiantamento-intro">
                  <h1>{t("pages.Adiantamentos.confirmaAdiantamentoTitulo")}</h1>
                  <p>
                    <Trans
                      i18nKey={t(
                        "pages.Adiantamentos.confirmaAdiantamentoTexto"
                      )}
                    />
                  </p>
                </div>
                <Divider />

                <Form
                  form={form}
                  onFinish={onFinish}
                  className="contrato"
                  layout="vertical"
                  onFieldsChange={handleFormChange}
                  initialValues={{
                    fiscalType: TipoPessoa.Fisica,
                    email: loggedUser.login,
                  }}
                >
                  <h2>
                    {t("pages.Adiantamentos.confirmaAdiantamentoTituloForm")}
                  </h2>
                  <span className="sub-titulo">
                    {t("pages.Adiantamentos.pessoaFisicaJuridica")}
                  </span>
                  <Form.Item
                    name="fiscalType"
                    label={t("pages.Adiantamentos.tipoPessoa")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.tipoPessoaRequired"),
                      },
                    ]}
                  >
                    <Select onChange={(value) => setTipoPessoa(value)}>
                      <Select.Option value={TipoPessoa.Fisica}>
                        {t("pages.Adiantamentos.pessoaFisica")}
                      </Select.Option>
                      <Select.Option value={TipoPessoa.Juridica}>
                        {t("pages.Adiantamentos.pessoaJuridica")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {tipoPessoa === TipoPessoa.Juridica && (
                    <>
                      <PessoaJuridicaForm />
                    </>
                  )}
                  <PessoaFisicaForm
                    titulo={
                      tipoPessoa === TipoPessoa.Fisica
                        ? t("pages.Adiantamentos.confirmaAdiantamentoFisica")
                        : t(
                            "pages.Adiantamentos.confirmaAdiantamentoFisicaRepresentante"
                          )
                    }
                  />
                  <span className="sub-titulo">
                    {t("pages.Adiantamentos.dadosBancarios")}
                  </span>
                  <Form.Item
                    name="pixKeyType"
                    label={t("pages.Adiantamentos.tipoChavePix")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.tipoChavePixRequired"),
                      },
                    ]}
                  >
                    <Select
                      options={pixKeyTypeList.map((item) => ({
                        ...item,
                        label: t(
                          `pages.Adiantamentos.${item.label.toLocaleLowerCase()}`
                        ),
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    name="pixKey"
                    label={t("pages.Adiantamentos.chavePix")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.chavePixRequired"),
                      },
                    ]}
                  >
                    <Input maxLength={100} />
                  </Form.Item>
                  <Form.Item
                    name="bankCode"
                    label={t("pages.Adiantamentos.codigoBanco")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.codigoBancoRequired"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      options={bankOptions}
                      loading={bankLoading}
                      filterOption={filterOptionDiacritics}
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name="bankAgency"
                    label={t("pages.Adiantamentos.agenciaBanco")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.agenciaBancoRequired"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>{" "}
                  <Form.Item
                    name="bankAccountNumber"
                    label={t("pages.Adiantamentos.numeroContaBanco")}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "pages.Adiantamentos.numeroContaBancoRequired"
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="currentDistributors"
                    label={t("pages.Adiantamentos.currentDistributors")}
                    extra={t("pages.Adiantamentos.currentDistributorsExtra")}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "pages.Adiantamentos.currentDistributorsRequired"
                        ),
                      },
                    ]}
                  >
                    <Select mode="tags" options={distroOptions} />
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        </div>
        {showMeuAdiantamento ? (
          <div className="footer">
            <Duvidas />
          </div>
        ) : (
          <div className="footer black">
            <button
              disabled={disabledSave}
              className="botao-roxo continuar"
              onClick={() => form.submit()}
            >
              {t("pages.Adiantamentos.continuar")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const PessoaFisicaForm: React.FC<any> = (props) => {
  const { titulo } = props;
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [loadingCEP, setLoadingCEP] = useState<boolean>(false);

  const fillAddressByCep = async (cep: string) => {
    try {
      if (cep) {
        setLoadingCEP(true);
        const response: any = await getAddressByCep(cep);
        form.setFieldsValue({
          street: response.street,
          state: response.uf,
          city: response.city,
        });
      }
    } finally {
      setLoadingCEP(false);
    }
  };
  return (
    <>
      <span className="sub-titulo">{titulo}</span>
      <Form.Item
        name="name"
        label={t("pages.Adiantamentos.nomeCompleto")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.nomeCompletoRequired"),
          },
          {
            pattern: /^\s*\S+(\s+\S+)+$/,
            transform: (value) => value?.trim(),
            message: t("pages.Adiantamentos.nomeSobrenomeValidation"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.nomeCompletoPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="email"
        label={t("pages.Adiantamentos.email")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.emailRequired"),
          },
        ]}
      >
        <Input
          placeholder={t("pages.Adiantamentos.emailPlaceholder")}
          disabled
        />
      </Form.Item>
      <Form.Item
        name="cpf"
        label={t("pages.Adiantamentos.cpf")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cpfRequired"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return formCPFValidator(value, getFieldValue, "cpf", t);
            },
          }),
        ]}
      >
        <MaskInput
          mask="999.999.999-99"
          placeholder={t("pages.Adiantamentos.cpfPlaceholder")}
        />
      </Form.Item>
      <Form.Item
        name="rg"
        label={t("pages.Adiantamentos.rg")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.rgRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.rgPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="rgPlaceExpedition"
        label={t("pages.Adiantamentos.rgPlaceExpedition")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.rgPlaceExpeditionRequired"),
          },
        ]}
      >
        <Input
          placeholder={t("pages.Adiantamentos.rgPlaceExpeditionPlaceholder")}
        />
      </Form.Item>
      <span className="sub-titulo">
        {t("pages.Adiantamentos.confirmaAdiantamentoEndereco")}
      </span>
      <Form.Item
        name="cep"
        label={t("pages.Adiantamentos.cep")}
        hasFeedback={loadingCEP}
        validateStatus={loadingCEP ? "validating" : undefined}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cepRequired"),
          },
        ]}
      >
        <Input
          onChange={debounce(
            (event) => fillAddressByCep(event.target.value),
            2000
          )}
          placeholder={t("pages.Adiantamentos.cepPlaceholder")}
          maxLength={8}
        />
      </Form.Item>
      <Form.Item
        name="street"
        label={t("pages.Adiantamentos.rua")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.ruaRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.ruaPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="streetNumber"
        label={t("pages.Adiantamentos.numero")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.numeroRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.numeroPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="addressComplement"
        label={t("pages.Adiantamentos.complemento")}
      >
        <Input placeholder={t("pages.Adiantamentos.complementoPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="state"
        label={t("pages.Adiantamentos.estado")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.estadoRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.estadoPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="city"
        label={t("pages.Adiantamentos.cidade")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cidadeRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.cidadePlaceholder")} />
      </Form.Item>
    </>
  );
};

const PessoaJuridicaForm: React.FC<any> = () => {
  const [loadingCEP, setLoadingCEP] = useState<boolean>(false);
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const fillAddressByCep = async (cep: string) => {
    try {
      if (cep) {
        setLoadingCEP(true);
        const response: any = await getAddressByCep(cep);
        form.setFieldsValue({
          companyStreet: response.street,
          companyState: response.uf,
          companyCity: response.city,
        });
      }
    } finally {
      setLoadingCEP(false);
    }
  };
  return (
    <>
      <span className="sub-titulo">
        {t("pages.Adiantamentos.confirmaAdiantamentoJuridica")}
      </span>
      <Form.Item
        name="businessName"
        label={t("pages.Adiantamentos.razaoSocial")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.razaoSocialRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.razaoSocialPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="cnpj"
        label={t("pages.Adiantamentos.cnpj")}
        // extra={t("pages.Adiantamentos.cpfExtra")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cnpjRequired"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return formCNPJValidator(value, getFieldValue, "cnpj", t);
            },
          }),
        ]}
      >
        <MaskInput
          mask="99.999.999/9999-99"
          placeholder={t("pages.Adiantamentos.cnpjPlaceholder")}
        />
      </Form.Item>
      <span className="sub-titulo">
        {t("pages.Adiantamentos.confirmaAdiantamentoEndereco")}
      </span>
      <Form.Item
        name="companyCEP"
        label={t("pages.Adiantamentos.cep")}
        hasFeedback={loadingCEP}
        validateStatus={loadingCEP ? "validating" : undefined}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cepRequired"),
          },
        ]}
      >
        <Input
          onChange={debounce(
            (event) => fillAddressByCep(event.target.value),
            2000
          )}
          placeholder={t("pages.Adiantamentos.cepPlaceholder")}
          maxLength={8}
        />
      </Form.Item>
      <Form.Item
        name="companyStreet"
        label={t("pages.Adiantamentos.rua")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.ruaRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.ruaPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="companyStreetNumber"
        label={t("pages.Adiantamentos.numero")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.numeroRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.numeroPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="companyAddressComplement"
        label={t("pages.Adiantamentos.complemento")}
      >
        <Input placeholder={t("pages.Adiantamentos.complementoPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="companyState"
        label={t("pages.Adiantamentos.estado")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.estadoRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.estadoPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="companyCity"
        label={t("pages.Adiantamentos.cidade")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cidadeRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.cidadePlaceholder")} />
      </Form.Item>
    </>
  );
};

export default AdiantamentoContratoAssinatura;
